import { appPaperContentClasses } from "@/components/AppPaperContent";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "AppPaper",
})((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
    },
    fullScreen: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      zIndex: theme.zIndex.backdrop - 100,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: theme.spacing(2.5),
      padding: theme.spacing(3.75),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3),
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
      [`& + .${appPaperContentClasses.root}`]: {
        paddingTop: 0,
      },
    },
    headerTitle: {
      flex: 1,
    },
    endAction: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2.5),
    },
    boxShadowMenuPopper: {
      boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.12)",
    },
    boxShadowCard: {
      boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.08)",
    },
  };
});

export default useStyles;

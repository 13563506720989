import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "AppDialog",
  uniqId: "KrGYZ3",
})(() => {
  return {
    paperFullScreen: {
      borderRadius: "initial",
    },
    scrollBody: {
      scrollBehavior: "smooth",
    },
  };
});

export default useStyles;

import axios from "axios";
import { bindActionCreators } from "redux";

import { storeAuthSelectors, storeNotificationAction } from "@/store";

import { useAppDispatch, useAppSelector } from "@/hooks";
import { useEffect, useMemo, useRef } from "react";

import type { CancelTokenSource } from "axios";

const AppInitialize = () => {
  const fetchNotificationCountItemSourceRef = useRef<CancelTokenSource | null>(
    null
  );
  const dispatch = useAppDispatch();

  const $s_notificationAction = useMemo(
    () => bindActionCreators(storeNotificationAction, dispatch),
    [dispatch]
  );

  const $s_hasAuth = useAppSelector(storeAuthSelectors.selectHasAuth);

  const fetchNotificationCountItem = () => {
    fetchNotificationCountItemSourceRef.current = axios.CancelToken.source();
    $s_notificationAction.fetchNotificationCountItemSaga(
      {
        cancelToken: fetchNotificationCountItemSourceRef.current.token,
      },
      {
        isReset: true,
      }
    );
  };

  useEffect(() => {
    !!$s_hasAuth && fetchNotificationCountItem();
    return () => {
      fetchNotificationCountItemSourceRef.current?.cancel &&
        fetchNotificationCountItemSourceRef.current.cancel();
    };
  }, [$s_hasAuth]);

  return null;
};

export default AppInitialize;

import { generateUtilityClasses } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const appPaperContentClasses = generateUtilityClasses(
  "AppPaperContent",
  ["root"]
);

const useStyles = makeStyles({
  name: "AppPaperContent",
})((theme) => {
  return {
    root: {
      padding: theme.spacing(3.75),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3),
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
    },
  };
});

export default useStyles;

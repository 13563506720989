import { forwardRef, useState } from "react";

import { Paper } from "@mui/material";
import AppTypography from "@/components/AppTypography";
import AppIconButton from "@/components/AppIconButton";
import AppTooltip from "@/components/AppTooltip";

import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";

import { useTranslation } from "react-i18next";

import useStyles from "./AppPaper.styles";

import type { PaperProps } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";

type CustomPaperProps = {
  boxShadowVariant?: "menuPopper" | "card";
  title?: React.ReactNode;
  showFullScreenAction?: boolean;
  endAction?: React.ReactNode;
};

export type AppPaperProps = Omit<PaperProps, keyof CustomPaperProps> &
  CustomPaperProps;

type AppPaperTypeMap<P = {}, D extends React.ElementType = "div"> = {
  props: P & AppPaperProps;
  defaultComponent: D;
};
type AppPaperComponent = OverridableComponent<AppPaperTypeMap>;

const AppPaper: AppPaperComponent = forwardRef(
  (props: AppPaperProps, ref: React.ForwardedRef<any>) => {
    const {
      className,
      classes: muiClasses,
      title,
      boxShadowVariant,
      showFullScreenAction,
      endAction,
      sx,
      children,
      ...rest
    } = props;

    const [isFullScreen, setIsFullScreen] = useState(false);

    const { classes, theme, cx, css } = useStyles();

    const { t } = useTranslation();

    return (
      <Paper
        ref={ref}
        classes={{
          ...muiClasses,
          root: cx(
            classes.root,
            {
              [classes.boxShadowMenuPopper]: boxShadowVariant === "menuPopper",
              [classes.boxShadowCard]: boxShadowVariant === "card",
              [classes.fullScreen]: isFullScreen,
            },
            muiClasses?.root,
            className,
            sx && css(theme.unstable_sx(sx) as any)
          ),
        }}
        elevation={0}
        {...rest}
      >
        {(!!title || !!endAction) && (
          <div className={classes.header}>
            <AppTypography
              className={classes.headerTitle}
              variant="titleSemi20"
              fontWeight={400}
              component="div"
              noWrap
            >
              {title}
            </AppTypography>
            {(endAction || showFullScreenAction) && (
              <div className={classes.endAction}>
                {showFullScreenAction && (
                  <AppTooltip title={t("fullScreen")}>
                    <AppIconButton
                      size="medium"
                      edge="xy"
                      onClick={() =>
                        setIsFullScreen((prevIsFullScreen) => !prevIsFullScreen)
                      }
                    >
                      {!isFullScreen ? (
                        <ZoomOutMapIcon fontSize="inherit" color="inherit" />
                      ) : (
                        <ZoomInMapIcon fontSize="inherit" color="inherit" />
                      )}
                    </AppIconButton>
                  </AppTooltip>
                )}
                {endAction}
              </div>
            )}
          </div>
        )}
        {children}
      </Paper>
    );
  }
);

export default AppPaper;
